import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><iframe width="100%" height="800px" src="https://umich.maps.arcgis.com/apps/Shortlist/index.html?appid=9efce1bb4c784e4e9291c3c5ba49b258">
</iframe>

<MDXTag name="p" components={components}>{` Although the film was unproduced, “The Heart of Darkness” left the names and addresses of many of the people involved in its pre-production for posterity. This information provides a detailed historical snapshot of the labor force RKO employed in 1939, including not only people who would have been listed in the credits, but also the many “below the line” workers--assistants, synopsis writers, so-called “script girls,” stock footage librarians, researchers, and others--whose work has largely vanished from historical accounts of the Hollywood studio system. By correlating this information with RKO payroll information, US census records, genealogical databases, and even voting registrations (which revealed individuals’ party affiliations) and emplotting it on historical maps of Los Angeles, we were able to create an interactive production history map of this aborted production that allows users to explore this unprecedented dataset. At a glance, one discovers, for example, that Jack Carter, the only African-American actor working on the film, who was to have played the “steersman” of the boat, lived miles away from the rest of the cast and crew, on South Central Avenue at the Dunbar Hotel, an important center for African-American social, cultural, and political life in pre-war Los Angeles. The map includes many of the locations documented in surviving evidence of “The Heart of Darkness,” including several area theaters where Welles watched movies, as well as the Los Angeles Public Library and the Natural History Museum where several researchers worked.`}</MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Production History Map","key":"production-history-map","parentKey":"heart-of-darkness","sortOrder":4};

  